body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #333;
}
.mobileshow {
  display: none;
}
.mobilenoshow {
  display: block;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.w-container:before,
.w-container:after {
  content: " ";
  display: table;
}
.w-container:after {
  clear: both;
}
.w-container .w-row {
  margin-left: -10px;
  margin-right: -10px;
}
.w-row:before,
.w-row:after {
  content: " ";
  display: table;
}
.w-row:after {
  clear: both;
}
.w-row .w-row {
  margin-left: 0;
  margin-right: 0;
}
.w-col {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}
.w-col .w-col {
  padding-left: 0;
  padding-right: 0;
}
.w-col-1 {
  width: 8.33333333%;
}
.w-col-2 {
  width: 16.66666667%;
}
.w-col-3 {
  width: 25%;
}
.w-col-4 {
  width: 33.33333333%;
}
.w-col-5 {
  width: 41.66666667%;
}
.w-col-6 {
  width: 50%;
}
.w-col-7 {
  width: 58.33333333%;
}
.w-col-8 {
  width: 66.66666667%;
}
.w-col-9 {
  width: 75%;
}
.w-col-10 {
  width: 83.33333333%;
}
.w-col-11 {
  width: 91.66666667%;
}
.w-col-12 {
  width: 100%;
}

body {
  font-family:'Source Sans Pro', sans-serif;
  color: #333;
  font-size: 16px;
  line-height: 22px;
}
h1 {
  display: block;
  margin-bottom: 19px;
  font-size: 38px;
  line-height: 44px;
  font-weight: 500;
}
h2 {
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
  text-align: center;
}
h3 {
  margin-bottom: 0px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  text-align: center;
}
h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  text-align: center;
}
h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  text-align: center;
}
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}
p {
  position: static;
  margin-bottom: 19px;
  padding-bottom: 0px;
  list-style-type: disc;
}
.button {
  display: inline-block;
  margin-right: 14px;
  padding: 13px 23px;
  border-radius: 3px;
  background-color: rgba(8, 0, 0, 0.78);
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px 0px;
  -webkit-transition: background-color 300ms ease, box-shadow 300ms ease, color 300ms ease;
  transition: background-color 300ms ease, box-shadow 300ms ease, color 300ms ease;
  font-family: Roboto, sans-serif;
  color: white;
  text-align: center;
  text-decoration: none;
}
.button:hover {
  border: 1px solid black;
  background-color: white;
  color: black;
}
.button.hollow {
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px 0px, black 0px 0px 0px 2px inset;
  color: black;
  text-align: center;
  text-shadow: none;
}
.button.hollow:hover {
  background-color: #489fd9;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px 0px, #489fd9 0px 0px 0px 2px inset;
  color: white;
  text-shadow: none;
}
.button.in-pricing {
  display: block;
  margin-top: 19px;
  margin-right: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.button.small-button {
  margin-top: 11px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
}
.logo-text {
  margin-top: 24px;
  font-family:'Droid Serif', serif;
  color: #1c1c1c;
  font-size: 29px;
  font-weight: 500;
}
.logo-text:hover {
  color: #4dbd90;
}
.logo-text.footer {
  margin-top: 0px;
  margin-bottom: 19px;
  color: #e6eaed;
}
.navigation {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 0px 0px;
}
.nav-link {
  padding: 26px 17px;
  box-shadow: transparent 0px 3px 0px 0px inset;
  -webkit-transition: box-shadow 500ms ease, color 300ms ease;
  transition: box-shadow 500ms ease, color 300ms ease;
  font-size: 18px;
}
.nav-link:hover {
  box-shadow: rgba(181, 181, 181, 0.49) 0px 3px 0px 0px inset;
  color: #4dbd90;
}
.nav-link.w--current {
  box-shadow: #4dbd90 0px 3px 0px 0px inset;
  color: #4dbd90;
}
.section {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 500;
}
.section.blue {
  background-color: #e3f3fc;
}
.section.main {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  background-color: #489fd9;
  color: white;
  text-shadow: rgba(0, 0, 0, 0.2) 0px 1px 0px;
}
.section.main.desktop {
  display: block;
  background-color: #4dbd90;
}
.section.main.cloud {
  background-color: #4dbd90;
}
.section.grey {
  background-color: #f0f0f0;
}
.section.image {
  background-position: 50% 50%;
  background-size: cover;
  color: white;
}
.section.footer {
  padding-top: 42px;
  padding-bottom: 42px;
  background-color: #2b2c2e;
  color: #e4e8eb;
}
.section.footer.copyright {
  padding-top: 19px;
  padding-bottom: 19px;
  background-color: #1c1d1f;
  color: #939596;
  font-size: 14px;
}
.section.purple {
  background-color: #dde7ed;
}
.section.clients {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #a1bfd4;
}
.section.press {
  display: none;
  padding-top: 29px;
  padding-bottom: 38px;
  background-color: #3e5c50;
}
.slider {
  display: none;
  height: 360px;
  background-color: #303030;
}
.slide {
  padding-top: 64px;
  padding-bottom: 64px;
}
.slide._1 {
  background-color: #00ad6e;
}
.slide._2 {
  background-color: #00bdd6;
}
.slide._3 {
  background-color: #ccbe00;
}
.main-subtitle {
  margin-bottom: 26px;
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
}
.footer-slogan {
  margin-bottom: 11px;
  color: #939596;
}
.social-icon {
  margin-right: 11px;
  opacity: 0.41;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}
.social-icon:hover {
  opacity: 1;
}
.footer-title {
  margin-top: 0px;
  font-family:'Roboto Condensed', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
.page-link {
  -webkit-transition: color 300ms ease, border 300ms ease;
  transition: color 300ms ease, border 300ms ease;
  color: #489fd9;
  text-decoration: none;
}
.page-link:hover {
  border-bottom-color: #489fd9;
}
.page-link.in-footer {
  display: block;
  color: #939596;
}
.page-link.in-footer:hover {
  border-bottom-color: transparent;
  color: #d2d7d9;
}
.page-link.white {
  border-bottom-color: rgba(255, 255, 255, 0.49);
  color: white;
}
.page-link.white:hover {
  border-bottom-color: white;
}
.newsletter-field {
  width: 60%;
  height: 40px;
  float: left;
  border: 1px solid transparent;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background-color: #e8e8e8;
}
.newsletter-field:focus {
  border-width: 1px;
  border-color: #09f;
  box-shadow: #489fd9 0px 0px 6px 0px;
}
.newsletter-button {
  width: 40%;
  height: 40px;
  padding-top: 9px;
  padding-bottom: 9px;
  float: left;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #5f6166;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #d5d8db;
}
.newsletter-button:hover {
  background-color: #489fd9;
  color: white;
}
.newsletter-form {
  width: 70%;
}
.brand-link {
  text-decoration: none;
}
.success-message {
  background-color: #5f6166;
}
.main-heading {
  margin-bottom: 20px;
  color: white;
  font-size: 47px;
  line-height: 44px;
  font-weight: 400;
}
.section-subtitle {
  margin-bottom: 68px;
  font-size: 19px;
  line-height: 22px;
  font-weight: 300;
  text-align: center;
}
.bold-text {
  font-weight: 400;
}
.plan-wrapper {
  min-height: 300px;
  padding: 17px;
  border-radius: 3px;
  background-color: white;
  color: #2b2b2b;
}
.price-point {
  margin-bottom: 8px;
  font-size: 14px;
}
.price-accent {
  color: #489fd9;
  font-size: 20px;
  letter-spacing: 0px;
}
.addl-feature {
  margin-bottom: 14px;
  font-size: 14px;
  line-height: 18px;
}
.addl-feature.inactive {
  color: #c7c5c5;
  text-decoration: line-through;
}
.divider {
  width: 50%;
  height: 1px;
  margin-top: 13px;
  margin-bottom: 12px;
  background-color: #dbdbdb;
}
.additional-contact {
  margin-top: 21px;
  text-align: center;
}
.feature {
  text-align: left;
}
.feature.centered {
  text-align: center;
}
.feature-row {
  margin-bottom: 14px;
}
.testimonial-slider {
  height: auto;
  margin-top: 45px;
  background-color: transparent;
}
.testimony-slide {
  padding-top: 0px;
  padding-right: 10%;
  padding-left: 10%;
  text-align: center;
}
.testimony-text {
  margin-bottom: 21px;
  font-family:'Roboto Slab', sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
}
.slide-nav {
  display: none;
  font-size: 10px;
}
.person {
  width: 70px;
  margin-bottom: 13px;
  border-radius: 100%;
}
.name {
  font-weight: 500;
}
.slider-arrow {
  width: 50px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: #bdbdbd;
  font-size: 32px;
}
.slider-arrow:hover {
  color: black;
}
.feature-icon {
  margin-bottom: 12px;
}
.main-feature-group {
  margin-top: 30px;
}
.center-contents {
  text-align: center;
}
.small-features-row {
  margin-top: 71px;
}
.main-image {
  position: relative;
  top: -43px;
  margin-top: 0px;
}
.client-logo {
  width: 16.6%;
}
.press-logo {
  width: 16%;
  margin-right: 2%;
  margin-left: 2%;
  opacity: 0.94;
}
.small-text {
  color: rgba(255, 255, 255, 0.29);
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}
.usermap {
  text-align: center;
}
.morebuttondiv {
  text-align: left;
}
.centering {
  float: none;
  clear: none;
  text-align: center;
}
.centering.div-white {
  background-color: white;
}
.centering.image-margin {
  margin-bottom: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.centering.youtube-margin {
  padding-top: 23px;
}
.white {
  background-color: white;
}
.product-wrapper {
  position: static;
  margin-bottom: 16px;
  padding: 16px 17px 24px;
  background-color: white;
}
.verticaldiv {
  float: right;
}
.horileft {
  width: auto;
  float: none;
  text-align: center;
  font-size: 12px;
}
.horiright {
  width: auto;
  float: none;
  clear: none;
  margin-right: 50px;
  text-align: right;
}
.dropdown-link {
  bottom: -6px;
  font-size: 18px;
}
.dropdown-link:hover {
  color: #4dbd90;
}
.top-spacer {
  margin-top: 4px;
  margin-bottom: 44px;
  padding-bottom: 0px;
}
.price-block {
  font-size: 24px;
  text-align: center;
}
.price-block.sale-price {
  color: red;
}
.price-block.strikeout {
  text-decoration: line-through;
}
.paypal {
  padding-top: 17px;
  text-align: center;
}
.buy-tab {
  font-size: 24px;
}
.buy-tabcontents {
  background-color: #bdd4f2;
}
.header-centering {
  margin-top: -40px;
  padding-top: 0px;
  color: white;
  text-align: center;
}
.text-centering {
  text-align: center;
}
.buydiv {
  display: block;
}
.spec-block {
  text-align: center;
}
.buy-wrapper {
  margin-bottom: 7px;
  padding: 9px 17px 15px;
  background-color: #bdd4f2;
}
.desktop {
  background-color: #4dbd90;
}
.titleveradjust {
  padding-top: 70px;
}
.tab-header {
  display: block;
  height: auto;
  padding-bottom: 0px;
  border-top: 1px none white;
  border-bottom: 1px none white;
  font-family:'Source Sans Pro', sans-serif;
  color: black;
  font-weight: 700;
}
html.w-mod-js *[data-ix="fade-in-on-load"] {
  opacity: 0;
  -webkit-transform: translate(0px, 24px);
  -ms-transform: translate(0px, 24px);
  transform: translate(0px, 24px);
}
html.w-mod-js *[data-ix="fade-in-on-scroll"] {
  opacity: 0;
  -webkit-transform: translate(0px, 24px);
  -ms-transform: translate(0px, 24px);
  transform: translate(0px, 24px);
}
@media (max-width: 991px) {
  .mobileshow {
    display: block;
  }
  .mobilenoshow {
    display: none;
  }
  .button:hover {
    background-color: white;
  }
  .button.in-pricing {
    font-size: 14px;
    line-height: 18px;
  }
  .nav-link {
    padding: 18px 0px;
    color: white;
  }
  .nav-link:hover {
    box-shadow: transparent 0px 3px 0px 0px inset;
  }
  .nav-link.w--current {
    box-shadow: transparent 0px 1px 0px 0px inset;
  }
  .nav-link.menu {
    padding: 22px 17px;
    color: black;
    font-size: 30px;
  }
  .nav-link.menu.w--open {
    background-color: black;
    color: white;
  }
  .nav-menu {
    padding-top: 13px;
    padding-bottom: 13px;
    background-color: black;
  }
  .main-feature-group {
    margin-top: 0px;
  }
  .main-image {
    margin-top: 49px;
  }
  .dropdown-link {
    color: white;
  }
  .dropdown-link.dropdown-submenu {
    color: black;
  }
  .dropdown-link.dropdown-submenu:hover {
    color: #4dbd90;
  }
  .dropdown-link.dropdown-submenu.w--current:hover {
    color: #4dbd90;
  }
}
@media (max-width: 767px) {
  h3 {
    margin-bottom: 8px;
  }
  p {
    text-align: left;
  }
  .logo-text {
    margin-top: 15px;
  }
  .nav-link.menu {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .section {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
  }
  .section.main {
    margin-top: 51px;
  }
  .section.main.desktop {
    padding-top: 51px;
  }
  .main-subtitle {
    text-align: center;
  }
  .nav-menu {
    padding-right: 12px;
    padding-left: 12px;
  }
  .footer-title {
    margin-top: 35px;
  }
  .newsletter-form {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .section-subtitle {
    margin-bottom: 44px;
  }
  .plan-wrapper {
    margin-right: 5%;
    margin-bottom: 34px;
    margin-left: 5%;
  }
  .divider {
    width: 100%;
  }
  .feature {
    margin-bottom: 32px;
    text-align: center;
  }
  .feature-icon {
    margin-bottom: 4px;
  }
  .main-feature-group {
    margin-top: 0px;
    margin-bottom: 34px;
  }
  .dropdown-link {
    color: white;
  }
  .listleft {
    text-align: left;
  }
}
@media (max-width: 479px) {
  .main-image {
    top: -35px;
  }
  .client-logo {
    width: 33.33%;
  }
  .press-logo {
    width: 28%;
  }
}

thead, tbody { display: block; }

tbody {
  height: 85px;       /* Just for the demo          */
  overflow-y: auto;    /* Trigger vertical scroll    */
  width: 800px;
}
table {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
  table-layout: fixed;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}
thead {
  width:800px;
}
tr {
  border: 1px solid #dee2e6;
}
th {
  text-align: left;
  white-space:nowrap;
  background-color: #343a40!important;
  color: #fff!important;
}
td {
  text-align: left;
  white-space:nowrap;
}

tr:hover {
  background-color: #ffff99;
}

.th_country {
  width: 200px;
}

.th_city {
  width: 480px;
}

.th_newcases {
  width: 180px;
}

.th_caserate {
  width: 240px;
}

.td_country {
  width: 200px;
}

.td_city {
  width: 400px;
}

.td_newcases {
  width: 200px;
}

.td_caserate {
  width: 200px;
}